<template>
  <div>
    <v-list-item
      v-if="lead.contact_lead_phone.length > 5"
      @click.stop="dialog = true"
    >
      <v-list-item-title>
        Convertir a broker externo
      </v-list-item-title>
    </v-list-item>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Confirmación
        </v-card-title>
        <v-card-text>
          ¿Está seguro que desea convertir el Lead a Broker externo? Esta opción
          <span class="red--text">no se puede deshacer</span>.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false">
            NO
          </v-btn>

          <v-btn :loading="loading" text @click="transforBroker()">
            SI
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { transformExternalBroker } from "@/api/external-broker-api.js";
export default {
  //transformExternalBroker

  name: "LeadTransformExternalBroker",
  data() {
    return {
      dialog: false,
      loading: false
    };
  },
  props: {
    lead: {
      type: Object,
      default: null
    }
  },
  components: {},

  created() {},
  methods: {
    transforBroker() {
      const data = {
        _id: this.lead._id
      };
      this.loading = true;
      transformExternalBroker(data).then(() => {
        window.location.reload();
      });
    }
  }
};
</script>
